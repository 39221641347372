import React from 'react'
import 'antd/dist/antd.min.css'
import { Button, Popover } from 'antd'
import { QuestionOutlined } from '@ant-design/icons'

const HelpPopover = ({ content, title }) => (
  <div className='helpPopover'>
    <div>
      <Popover placement='topRight' title={title} content={content} trigger='click'>
        <Button icon={<QuestionOutlined />} />
      </Popover>
    </div>
  </div>
)

export default HelpPopover
