export const requestGraphQlApi = async (query: string, token: string): Promise<Object | any> => {
  try {
    const response = await fetch('https://hpm.agency/api/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({ query })
    })

    if (response.status !== 200) {
      throw new Error(`GraphQL request failed with status ${response.status}`)
    }
    return await response.json()
  } catch (err) {
    if (err instanceof Error) {
      console.error(err.message)
    } else {
      console.error('Unexpected error', err)
    }
  }
}

export const tokenHasGraphQlAccess = async (token: string): Promise<boolean> => {
  const response = await requestGraphQlApi(returnCurrentUserQuery(), token)
  if (!response.data.currentUser) {
    return false
  }
  return true
}

const returnCurrentUserQuery = (): string => {
  return `{
  currentUser {
    id
  }
}`
}
