import React from 'react'
import 'antd/dist/antd.min.css'
import { Radio, Row, Col } from 'antd'

const RadioButtons = ({ buttonValues, onClick }) => {
  const radioButtons = []
  for (let buttonIndex = 0; buttonIndex < buttonValues.length; buttonIndex++) {
    const buttonValue = buttonValues[buttonIndex]
    radioButtons[buttonIndex] = (
      <Col span={12}>
        <Radio.Button style={{ width: '100%' }} onClick={onClick} value={buttonValue} key={buttonIndex}>{buttonValue}</Radio.Button>
      </Col>
    )
  }
  const firstButtonValue = buttonValues[0]

  return (
    <>
      <Radio.Group style={{ width: '100%' }} size='large' defaultValue={firstButtonValue} buttonStyle='solid'>
        <Row>
          {radioButtons}
        </Row>
      </Radio.Group>
    </>
  )
}

export default RadioButtons
