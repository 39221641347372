import React, { useContext } from 'react'
import { AuthContext } from '../oauth/AuthContext'
import SignatureSelection from './SignatureSelection'
import Guides from './Guides'
import 'antd/dist/antd.min.css'
import { Tabs, Button } from 'antd'

const { TabPane } = Tabs

const NavigationBar = () => {
  const { logoutFlow } = useContext(AuthContext)
  return (
    <div>
      <Button size='small' type='dashed' onClick={() => logoutFlow()} value='Logout' danger>Abmelden</Button>
      <Tabs defaultActiveKey='1'>
        <TabPane tab='Generator' key='signature_generator_tab_1'>
          <SignatureSelection />
        </TabPane>
        <TabPane tab='Anleitungen' key='signature_guides_tab_2'>
          <Guides />
        </TabPane>
      </Tabs>
    </div>
  )
}

export default NavigationBar
