import 'antd/dist/antd.min.css'
import { Drawer } from 'antd'

const BottomDrawer = ({ visible, onClose, content }) => {
  return (
    <>
      <Drawer
        placement='bottom'
        closable={false}
        onClose={onClose}
        visible={visible}
        key='bottom'
      >
        {content}
      </Drawer>
    </>
  )
}

export default BottomDrawer
