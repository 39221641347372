const getUserData = async (token) => {
  const repositoryUrl = process.env.REACT_APP_REPOSITORY_URL
  try {
    const response = await window.fetch(repositoryUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
    const userData = await response.json()
    return userData
  } catch (error) {
    throw new Error('No userData found', error)
  }
}

export default getUserData
