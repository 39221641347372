
const HandleRedirect = () => {
  return (
    <div>
      Weiterleiten um das Einloggverfahren abzuschließen...
    </div>
  )
}

export default HandleRedirect
