import './App.css'
import { AuthProvider } from './oauth/AuthContext'
import LoginPage from './components/LoginPage'
import NavigationTabs from './components/NavigationTabs'
import HandleRedirect from './components/HandleRedirect'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

const basePath = process.env.REACT_APP_BASE_PATH ?? ''

const authConfig = {
  clientId: process.env.REACT_APP_OAUTH_CLIENT_ID || '26d9ff91d57b8fc961c01809f01dc4c2cd48d8f211636190797f33232d5192aa',
  authorizationEndpoint: process.env.REACT_APP_OAUTH_AUTH_ENDPOINT || 'https://hpm.agency/oauth/authorize',
  tokenEndpoint: process.env.REACT_APP_OAUTH_TOKEN_ENDPOINT || 'https://hpm.agency/oauth/token',
  scope: process.env.REACT_APP_OAUTH_SCOPE || 'read_user read_api', // space delimited
  redirectUri: process.env.REACT_APP_OAUTH_REDIRECT_URI || 'http://localhost:3000/auth-redirect',
  logoutRedirect: process.env.REACT_APP_OAUTH_LOGOUT_URI || 'http://localhost:3000/',
  revokeEndpoint: process.env.REACT_APP_OAUTH_REVOKE_ENDPOINT || 'https://hpm.agency/oauth/revoke',
  postLogin: () => (window.location.href = `${basePath}/signature`),
  preLogin: () => null
}

function App () {
  return (
    <BrowserRouter>
      <AuthProvider authConfig={authConfig}>
        <div className='App'>
          <div className='App-innerContainer'>
            <div id='header'>Signatur Generator</div>
            <div id='components'>
              <Routes>
                <Route
                  path='/'
                  element={<LoginPage />}
                />
                <Route
                  path='/auth-redirect'
                  element={<HandleRedirect />}
                />
                <Route
                  path='/signature'
                  element={<NavigationTabs />}
                />
                <Route
                  path='*'
                  element={<div id='title'>404, Not Found!</div>}
                />
              </Routes>
            </div>
          </div>
        </div>
      </AuthProvider>
    </BrowserRouter>
  )
}

export default App
