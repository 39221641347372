import React from 'react'
import 'antd/dist/antd.min.css'
import { Select } from 'antd'

const { Option } = Select

const Selector = ({ selectorOptions, onChange, value }) => {
  const options = []
  for (let optionIndex = 0; optionIndex < selectorOptions.length; optionIndex++) {
    const optionValue = selectorOptions[optionIndex]
    options[optionIndex] = <Option value={optionValue} key={optionIndex}>{optionValue}</Option>
  }

  return (
    <Select
      value={value}
      style={{ width: '100%' }}
      onChange={onChange}
    >
      {options}
    </Select>
  )
}

export default Selector
