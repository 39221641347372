import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../oauth/AuthContext'
import Selector from './Selector'
import RadioButtons from './RadioButtons'
import BottomDrawer from './BottomDrawer'
import generateSignature from './generateSignature'
import getUserData from './getUserData'
import HelpPopover from './HelpPopover'
import { Col, Row, Button, Space } from 'antd'
import 'antd/dist/antd.min.css'
import 'antd/dist/antd.css'

const SignatureSelection = () => {
  const { token, requestGraphQlData } = useContext(AuthContext)
  const [selectedAddress, setSelectedAddress] = useState('')
  const [selectedLanguage, setSelectedLanguage] = useState('Deutsch')
  const [selectedFormat, setSelectedFormat] = useState('Text')
  const [username, setUsername] = useState('')
  const [bottomDrawerIsVisible, setBottomDrawerIsVisible] = useState(false)
  const [signature, setSignature] = useState(<div />)
  const [userData, setUserData] = useState({})
  const [selectorOptions, setSelectorOptions] = useState([username, 'jobs', 'human.resources', 'production', 'infrastructure', 'billing'])
  const languageKeys = ['Deutsch', 'Englisch']
  const formatKeys = ['Text', 'Code']
  const popoverTitle = 'Nutzer / Gruppenzuweisung'
  const popoverContent = (
    <div>
      <p>
        Wähle dein Kürzel oder das einer Gruppe, für die diese Signatur genutzt werden soll.
        <br />
        Dadurch ändert sich die zum Kontakt angegebene E-Mail Adresse.
      </p>
    </div>
  )

  useEffect(() => {
    fetchData()
  }, []) // eslint-disable-line

  useEffect(() => {
    setSelectorOptions([username, 'jobs', 'human.resources', 'production', 'infrastructure', 'billing'])
  }, [username])

  const fetchData = async () => {
    const currentUserQuery = `{
      currentUser {
        username
      }
    }`
    const request = await requestGraphQlData(currentUserQuery)
    const requestUserData = await getUserData(token)
    setUserData(requestUserData)
    const usernameData = request?.data?.currentUser?.username || ''
    setUsername(usernameData)
    setSelectedAddress(usernameData)
  }

  const handleSelectionChange = (value) => {
    setSelectedAddress(value)
  }

  const handleLanguageButtonOnClick = (event) => {
    setSelectedLanguage(event.target.defaultValue)
  }

  const handleFormatButtonOnClick = (event) => {
    setSelectedFormat(event.target.defaultValue)
  }

  const handleGenerateSignatureOnClick = async () => {
    await setSignature(generateSignature(selectedLanguage, selectedFormat, selectedAddress, username, userData))
    await setBottomDrawerIsVisible(true)
  }

  const handleBottomDrawerOnClose = () => {
    setBottomDrawerIsVisible(false)
  }

  return (
    <>
      <Space size='middle' direction='vertical'>
        <Row justify='space-between'>
          <Col span={20}>
            <Selector selectorOptions={selectorOptions} onChange={handleSelectionChange} value={selectedAddress} />
          </Col>
          <Col span={4}>
            <HelpPopover content={popoverContent} title={popoverTitle} />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <RadioButtons onClick={handleLanguageButtonOnClick} buttonValues={languageKeys} />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <RadioButtons onClick={handleFormatButtonOnClick} buttonValues={formatKeys} />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Button block size='large' type='primary' onClick={handleGenerateSignatureOnClick} value='Erstelle Signatur'>Erstelle Signatur</Button>
          </Col>
        </Row>
      </Space>
      <BottomDrawer visible={bottomDrawerIsVisible} onClose={handleBottomDrawerOnClose} content={signature} />
    </>
  )
}

export default SignatureSelection
