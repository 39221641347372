import React, { useContext } from 'react'
import { Col, Row, Button } from 'antd'
import { AuthContext } from '../oauth/AuthContext'

const LoginPage = () => {
  const { loginFlow } = useContext(AuthContext)
  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={16}>
          <Button onClick={(event) => loginFlow()}>Mit Gitlab einloggen</Button>
        </Col>
      </Row>
    </>
  )
}

export default LoginPage
