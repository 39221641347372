import React from 'react'
import 'antd/dist/antd.min.css'
import { Divider } from 'antd'
import matchMessageFontCheckbox from '../lib/matchMessageFontCheckbox.png'

const Guides = () => {
  return (
    <>
      <div>
        <br />
        <h2>Gmail</h2>
        <p>
          - Hier geht es weiter zur offiziellen Anleitung für&nbsp;
          <a href='https://support.google.com/mail/answer/8395?hl=en&co=GENIE.Platform%3DDesktop' rel='noopener noreferrer' target='_blank'>Gmail</a>
          <br />
          - Die Signatur hierbei als Text ausgeben und an der vom Guide angegebenen Stelle einfügen.
        </p>
      </div>
      <Divider />
      <br />
      <div>
        <h2>Apple Mail</h2>
        <p>
          - Hier geht es weiter zur offiziellen Anleitung für&nbsp;
          <a href='https://support.apple.com/guide/mail/create-and-use-email-signatures-mail11943/mac' rel='noopener noreferrer' target='_blank'>Apple Mail</a>
          <br />
          - Die Signatur hierbei als Text ausgeben und an der vom Guide angegebenen Stelle einfügen.
          <br />
          - Zubeachten ist zudem, dass das Kästchen mit "Always match my default message font" nicht angehakt ist.
        </p>
        <img src={matchMessageFontCheckbox} alt='Always match Message Font Checkbox' />
      </div>
      <Divider />
      <br />
      <div>
        <h2>Thunderbird</h2>
        <p>
          - Hier geht es weiter zur offiziellen Anleitung für&nbsp;
          <a href='https://support.mozilla.org/en-US/kb/signatures' rel='noopener noreferrer' target='_blank'>Thunderbird</a>
          <br />
          - Dem Guide zur HTML Signatur folgen und die Signatur als <b>Code</b> einfügen
        </p>
      </div>
      <Divider />
    </>
  )
}

export default Guides
